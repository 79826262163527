/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { action } from "typesafe-actions";

export const actionTypes = {
  DO_LOGIN: "Auth/DoLogin",
  DO_HANDLE_ALREADY_LOGGED_IN: "Auth/DoHandleAlreadyLoggedIn",
  SET_USER_LOGGED_IN: "Auth/SetUserLoggedIn",
  SET_USER_LOGGED_OUT: "Auth/SetUserLoggedOut",
  SET_LOGIN_REDIRECT_PATH: "Auth/SetLoginRedirectPath",
  SET_FIRST_TOKEN: "Auth/SetFirstToken",
  SET_AUTH_TOKEN: "Auth/SetAuthToken",
  SET_FINISHED_CONFIRMATION: "Auth/SetFinishedConfirmation",
  SET_TIMEOUT_TIMESTAMP: "Auth/SetTimeoutTimestamp",
  SET_REMAINING_TIME: "Auth/SetRemainingTime",
  SET_LOGIN_ERROR: "Auth/SetLoginError",
  RESET_STORE: "Auth/ResetStore",
  SET_SHOULD_RESET_CLIENT: "Auth/SetShouldResetClient",
};

export const actions = {
  setUserLoggedIn: () => action(actionTypes.SET_USER_LOGGED_IN),
  setUserLoggedOut: () => action(actionTypes.SET_USER_LOGGED_OUT),
  setLoginRedirectPath: (path?: string) =>
    action(actionTypes.SET_LOGIN_REDIRECT_PATH, path),
  setShouldResetClient: (data: boolean) =>
    action(actionTypes.SET_SHOULD_RESET_CLIENT, data),

  resetStore: () => action(actionTypes.RESET_STORE),
};
