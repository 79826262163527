import * as React from "react";
import {
  HeaderContainer,
  HeaderContainerLeft,
  HeaderContainerRight,
} from "./style";
import CinemaMenu from "../../components/cinema_menu/cinema_menu";
import ShowmenuDeploy from "../../components/showmenu_deploy/showmenu_deploy";

function HeaderContent(): JSX.Element {
  return (
    <HeaderContainer>
      <HeaderContainerLeft>
        <CinemaMenu />
      </HeaderContainerLeft>
      <HeaderContainerRight>
        <ShowmenuDeploy />
      </HeaderContainerRight>
    </HeaderContainer>
  );
}

export default HeaderContent;
