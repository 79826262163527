import { IStore } from "../IStore";
import { DateTime } from "luxon";

export const getLoading = (state: IStore): number => state.app.loading;
export const getSelectedClient = (state: IStore): number =>
  state.app.selectedClient;
export const getSelectedLocation = (state: IStore): number =>
  state.app.selectedLocation;
export const getLastMovieQuery = (state: IStore): DateTime | undefined =>
  state.app.lastMovieQuery;
export const getLastShowDeploy = (state: IStore): DateTime | undefined =>
  state.app.lastShowDeploy;
