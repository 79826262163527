import * as React from "react";
import { Menu, notification } from "antd";
const { SubMenu } = Menu;
import { useDispatch } from "react-redux";
import { LogoutOutlined } from "@ant-design/icons";
import Auth from "../../logic/redux/auth";
import { gql, useQuery, useMutation } from "@apollo/client";
import { me } from "./__generated__/me";
import { logout } from "./__generated__/logout";

function UserMenu(): JSX.Element {
  const dispatch = useDispatch();
  const { data: user, error: user_error } = useQuery<me>(gql`
    query me {
      me {
        id
        email
        name
      }
    }
  `);

  const [callLogout, { error: logoutError }] = useMutation<logout>(gql`
    mutation logout {
      logout
    }
  `);

  React.useEffect(() => {
    if (user_error) {
      notification["error"]({
        message: "Hiba történt a felhasználó betöltésekor",
      });
    }
  }, [user_error]);

  React.useEffect(() => {
    if (logoutError) {
      notification["error"]({
        message: "Hiba történt a kijelentkezéskor",
      });
    }
  }, [logoutError]);

  function handleLogout() {
    callLogout().then(() => {
      //dispatch(Auth.actions.setUserLoggedOut());
      dispatch(Auth.actions.setShouldResetClient(true));
    });
    // TODO: backend logout
  }

  return (
    <Menu mode="horizontal" defaultOpenKeys={[]} triggerSubMenuAction={"click"}>
      <SubMenu key="user" title={user ? user.me.name : "Menu"}>
        <Menu.Item key="1" icon={<LogoutOutlined />} onClick={handleLogout}>
          Kijelentkezés
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
}

export default UserMenu;
