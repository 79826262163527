import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";

//import { useHistory } from "react-router-dom";
//import { RouteNames } from "../routes/routes";
//import { history } from "../logic/redux/store";

const isDevelopment =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";
const backendURL = process.env.REACT_APP_API
  ? process.env.REACT_APP_API
  : "localhost:8090";

export const API_URL = isDevelopment
  ? "http://" + window.location.hostname + ":8090"
  : "https://" + backendURL;

const GRAPHQL_API_URL = isDevelopment
  ? "http://" + window.location.hostname + ":8090/query"
  : "https://" + backendURL + "/query";
const WS_API_URL = isDevelopment
  ? "ws://" + window.location.hostname + ":8090/query"
  : "wss://" + backendURL + "/query";
const token = sessionStorage.getItem("token");

const wsClient = new WebSocketLink({
  uri: WS_API_URL,
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        Authorization: token ? token : "",
      },
    },
  },
});

const httpLink = new HttpLink({
  uri: GRAPHQL_API_URL,
  credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem("token");
  return {
    headers: {
      ...headers,
      Authorization: token ? token : "",
    },
  };
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsClient,
  authLink.concat(httpLink)
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      /*if (message === "unauthorized") {
        history.push(RouteNames.LOGIN);
      }*/
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const createGraphqlClient = (): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, link]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "all",
      },
      query: {
        fetchPolicy: "network-only",
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
    },
  });

let graphqlClient = createGraphqlClient();

export const resetGraphqlClient = (): ApolloClient<NormalizedCacheObject> => {
  const newClient = createGraphqlClient();
  graphqlClient = newClient;
  return newClient;
};

export default graphqlClient;
