import * as React from "react";
import { Form, Input, Button, notification } from "antd";
import { LoginForm, Logo } from "./style";
import Assets from "../../../src/assets";
import Auth from "../../logic/redux/auth";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { CinemaRouteNames } from "../../routes/routes";
import { gql, useMutation } from "@apollo/client";
import { login, loginVariables } from "./__generated__/login";

function Login(): JSX.Element {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const dispatch = useDispatch();
  const loggedIn = useSelector(Auth.select.selectLoggedIn);
  const redirectPath = useSelector(Auth.select.selectLoginRedirectPath);

  const [loginCall, { loading: loginLoading, error: loginError, data }] =
    useMutation<login, loginVariables>(gql`
      mutation login($email: String!, $password: String!) {
        login(email: $email, password: $password)
      }
    `);

  React.useEffect(() => {
    if (loginError) {
      switch (loginError.message) {
        case "wrong_email_or_password":
          notification["error"]({
            message: "Hibás e-mail cím vagy jelszó!",
          });
          break;
        default:
          notification["error"]({
            message: "Hiba a bejelentkezéskor!",
          });
      }
    }
  }, [loginError]);

  React.useEffect(() => {
    if (data) {
      sessionStorage.setItem("token", data.login);
      dispatch(Auth.actions.setUserLoggedIn());
      dispatch(Auth.actions.setShouldResetClient(true));
      //return <Redirect to={RouteNames.MAIN} />;
    }
  }, [data]);

  if (loggedIn && redirectPath) {
    return <Redirect to={redirectPath} />;
  } else if (loggedIn) {
    return <Redirect to={CinemaRouteNames.MOVIES} />;
  }

  if (loginLoading) return <p>Loading ...</p>;

  return (
    <LoginForm error={loginError !== undefined}>
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFinish={(values: any) => {
          loginCall({
            variables: {
              email: values.email,
              password: values.password,
            },
          }).catch((e) => {
            console.log(e);
          });
        }}
      >
        <Logo>
          <img src={Assets.logo.foodiest_logo} />
        </Logo>

        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Email megadása kötelező!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Jelszó"
          name="password"
          rules={[{ required: true, message: "Jelszó megadása kötelező!" }]}
        >
          <Input.Password />
        </Form.Item>
        {/*renderError()*/}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Belépés
          </Button>
        </Form.Item>
      </Form>
    </LoginForm>
  );
}

export default Login;
