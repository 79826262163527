import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderContainerLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const HeaderContainerRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
