import styled from "styled-components";

interface IColorWithText {
  color: string;
  textColor: string;
}

interface IColor {
  color: string;
}

interface ISelected {
  selected: boolean;
}

export const CinemContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  display: flex;
  width: 100%;
`;

export const Categories = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Category = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CategoryEdit = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CategoryData = styled.div<ISelected>`
  background-color: ${(props) => (props.selected ? "#E6F7FF" : "none")};
  display: flex;
  flex-direction: row;
`;

export const Color = styled.div<IColorWithText>`
  width: 25px;
  height: 56px;
  background-color: ${(props) => props.color};
  border: ${(props) => (props.textColor ? "1px solid " + props.textColor : "")};
  border-radius: 2px;
`;

export const CategoryNameContainer = styled.div<ISelected>`
  color: ${(props) => (props.selected ? "#1890FF" : "#000000")};
  width: 195px;
  height: 56px;
  padding: 0 10px;
  vertical-align: middle;
  line-height: 56px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-conten: center;
  align-items: center;
  height: 56px;
  width: 40px;
`;

export const CategoryName = styled.span`
  margin: auto;
`;

export const NewLink = styled.div``;

export const PaginatorContainer = styled.div`
  margin: 0 auto;
  padding-top: 20px;
`;

export const MovieTitle = styled.p`
  margin: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
`;

export const CategoryTable = styled.div`
  tbody {
    tr {
      td {
        padding: 0px 10px 0px 0px;
      }
    }
  }
`;

export const MovieEditButtons = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 30px;
`;

export const ColoredText = styled.span<IColor>`
  color: ${(props) =>
    props.color && props.color !== "" ? props.color : "none"};
`;

/*export const ColorBorder = styled.div<IColor>`
  width: 100%;
  height: 100%;
  border: ${(props) => (props.color ? "1px solid " + props.color : "")};
  border-radius: 2px;
`;*/
