import * as React from "react";
import { CinemaContainer } from "./style";
import HeaderContent from "../cinema_header/header";
import { CinemaRoutes } from "../../routes/routes";

function Cinema(): JSX.Element {
  return (
    <CinemaContainer>
      <HeaderContent />
      <CinemaRoutes />
    </CinemaContainer>
  );
}

export default Cinema;
