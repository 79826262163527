import * as React from "react";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import { CinemaRouteNames } from "../../routes/routes";

function CinemaMenu(): JSX.Element {
  const history = useHistory();

  function handleMovies() {
    history.push(CinemaRouteNames.MOVIES);
  }

  /*function handleHalls() {
    history.push(CinemaRouteNames.HALLS);
  }

  function handleTickets() {
    history.push(CinemaRouteNames.TICKETS);
  }

  function handleDeploys() {
    history.push(CinemaRouteNames.DEPLOYS);
  }*/

  function getActive(): string {
    switch (window.location.pathname) {
      case "/cinema":
      case CinemaRouteNames.MOVIES:
        return "1";
      case CinemaRouteNames.HALLS:
        return "2";
      case CinemaRouteNames.TICKETS:
        return "3";
      case CinemaRouteNames.DEPLOYS:
        return "4";
    }
    return "1";
  }

  return (
    <Menu mode="horizontal" activeKey={getActive()} defaultOpenKeys={[]}>
      <Menu.Item key="1" onClick={handleMovies}>
        Filmek
      </Menu.Item>
      {/*<Menu.Item key="2" onClick={handleHalls}>
        Termek
      </Menu.Item>
      <Menu.Item key="3" onClick={handleTickets}>
        Jegyek
      </Menu.Item>
      <Menu.Item key="4" onClick={handleDeploys}>
        Aktiválások
      </Menu.Item>*/}
    </Menu>
  );
}

export default CinemaMenu;
