import produce from "immer";
import { IUserReducer } from "./user-models";
import { actionTypes } from "./user-actions";

const initializeState: IUserReducer = {
  name: "",
  webID: 0,
  email: "",
  phone: "",
  companyName: "",
  billingAddress: {},
  deliveryAddress: {},
  preOrderDiscount: false,
  auctionOrder: false,
  acOrder: false,
  kitchenOrder: false,
  acVisible: false,
  kitchenVisible: false,
  visibleMenus: [],
  visibleAcCategories: [],
  visibleKitchenCategories: [],
  visibleAcManufacturers: [],
  visibleKitchenManufacturers: [],
  ptPoints: 0,
  pointDiscount: false,
  token: "",
  paymentMethods: [],
  createDeleteProject: false,
  createDeleteRequest: false,
  usersRole: "",
};

export default (
  state = initializeState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: { type: string; payload: any }
): IUserReducer =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.SET_USER:
        draft = action.payload;
        return draft;
      default:
        return state;
    }
  });
