import styled from "styled-components";

interface IWarn {
  warn: boolean;
}

export const Container = styled.div`
  align-items: center;
  display: flex;
  margin-right: 15px;
`;

export const LastUpdate = styled.span`
  padding-right: 15px;
`;

export const LastQuery = styled.span<IWarn>`
  color: ${(props) => (props.warn ? "#ff4d4f" : "none")};
  padding-right: 55px;
`;
