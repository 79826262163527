import { action } from "typesafe-actions";
import { IUserReducer } from "./user-models";
import { getCurrentUser } from "./user-thunk-actions";

export const actionTypes = {
  SET_USER: "user/SetUser",
};

export const actions = {
  setUser: (payload: IUserReducer): { type: string; payload: IUserReducer } =>
    action(actionTypes.SET_USER, payload),
  getCurrentUser,
};
