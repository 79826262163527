import * as React from "react";
import { Select, notification } from "antd";
const { Option } = Select;
import { Container } from "./style";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getSelectedClient,
  getSelectedLocation,
} from "../../logic/redux/app/app-selectors";
import App from "../../logic/redux/app";
import { gql, useLazyQuery } from "@apollo/client";
import { locations, locationsVariables } from "./__generated__/locations";
import { queryUpdater } from "../../helpers/utils";
import { useHistory, useLocation } from "react-router-dom";

export const LOCATION = "location";

function LocationSelector(): JSX.Element {
  const history = useHistory();
  const search = useLocation().search;
  const location = new URLSearchParams(search).get(LOCATION);
  const selectedClient = useSelector(getSelectedClient, shallowEqual);
  const selectedLocation = useSelector(getSelectedLocation, shallowEqual);
  const dispatch = useDispatch();

  const [
    getLocations,
    { data: locations, /*loading,*/ error: locations_error },
  ] = useLazyQuery<locations, locationsVariables>(
    gql`
      query locations($client_id: Int!) {
        locations(client_id: $client_id) {
          id
          name
        }
      }
    `,
    {
      variables: { client_id: selectedClient },
      fetchPolicy: "no-cache",
    }
  );

  React.useEffect(() => {
    if (locations_error) {
      notification["error"]({
        message: "Hiba történt az adatok betöltésekor",
      });
    }
  }, [locations_error]);

  React.useEffect(() => {
    if (selectedClient > 0) {
      getLocations({ variables: { client_id: selectedClient } });
    }
  }, [selectedClient]);

  React.useEffect(() => {
    if (location && locations) {
      const client_id = Number(location);
      const found = locations.locations.find((c) => c.id === client_id);
      if (found) {
        dispatch(App.actions.setLocation(found.id));
      } else {
        if (locations.locations.length > 0) {
          dispatch(App.actions.setLocation(locations.locations[0].id));
          const querystring = queryUpdater(
            search,
            LOCATION,
            locations.locations[0].id.toString()
          );
          history.push(`${history.location.pathname}${querystring}`);
        }
      }
    } else if (!location && locations) {
      if (locations.locations.length > 0) {
        dispatch(App.actions.setLocation(locations.locations[0].id));
        const querystring = queryUpdater(
          search,
          LOCATION,
          locations.locations[0].id.toString()
        );
        history.push(`${history.location.pathname}${querystring}`);
      }
    }
  }, [location, locations]);

  React.useEffect(() => {
    if (selectedLocation) {
      const querystring = queryUpdater(
        search,
        LOCATION,
        selectedLocation.toString()
      );
      history.push(`${history.location.pathname}${querystring}`);
    }
  }, [selectedLocation]);

  function handleChange(value: number) {
    dispatch(App.actions.setLocation(value));
  }

  return (
    <Container>
      {locations &&
        (locations.locations.length > 1 ? (
          <Select
            value={selectedLocation}
            style={{ width: 220 }}
            onChange={handleChange}
          >
            {locations.locations.map((location) => {
              return (
                <Option key={"location_" + location.id} value={location.id}>
                  {
                    // eslint-disable-next-line no-prototype-builtins
                    location.name && location.name.hasOwnProperty("hu")
                      ? location.name["hu"]
                      : ""
                  }
                </Option>
              );
            })}
          </Select>
        ) : (
          locations.locations.length === 1 && (
            <div>
              {locations.locations[0].name &&
              // eslint-disable-next-line no-prototype-builtins
              locations.locations[0].name.hasOwnProperty("hu")
                ? locations.locations[0].name["hu"]
                : ""}
            </div>
          )
        ))}
    </Container>
  );
}

export default LocationSelector;
