import * as React from "react";
import {
  HeaderContainer,
  HeaderContainerLeft,
  HeaderContainerRight,
} from "./style";
import UserMenu from "../../components/user_menu/user_menu";
import ClientSelector from "../../components/client_selector/client_selector";
import LocationSelector from "../../components/location_selector/location_selector";

function HeaderContent(): JSX.Element {
  return (
    <HeaderContainer>
      <HeaderContainerLeft>
        <ClientSelector />
        <LocationSelector />
      </HeaderContainerLeft>
      <HeaderContainerRight>
        <UserMenu />
      </HeaderContainerRight>
    </HeaderContainer>
  );
}

export default HeaderContent;
