export function queryUpdater(
  search: string,
  type: string,
  value: string
): string {
  let query = "";
  if (search) {
    const searchParams = new URLSearchParams(search);
    if (searchParams.has(type)) {
      searchParams.set(type, value);
      query = searchParams.toString();
    } else {
      searchParams.append(type, value);
      query = searchParams.toString();
    }
  } else {
    query = `?${type}=${value}`;
  }
  if (query && !query.includes("?")) {
    query = "?" + query;
  }
  return query;
}

export function queryRemover(search: string, type: string): string {
  const searchParams = new URLSearchParams(search);
  if (search && searchParams.has(type)) {
    searchParams.delete(type);
    search = searchParams.toString();
  }
  if (search && !search.includes("?")) {
    search = "?" + search;
  }
  return search;
}
