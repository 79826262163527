import * as React from "react";
import { Button, Modal, Table, Empty, Input, Form, notification } from "antd";
import { Container, LastUpdate, LastQuery } from "./style";
import {
  gql,
  useLazyQuery,
  useMutation,
  useSubscription,
} from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedLocation } from "../../logic/redux/app/app-selectors";
import {
  getLastMovieQuery,
  getLastShowDeploy,
} from "../../logic/redux/app/app-selectors";

import { kiosks, kiosksVariables } from "./__generated__/kiosks";
import {
  deploy_showmenu,
  deploy_showmenuVariables,
} from "./__generated__/deploy_showmenu";
import { lastDeploy, lastDeployVariables } from "./__generated__/lastDeploy";
import App from "../../logic/redux/app";
import { DateTime } from "luxon";

const { TextArea } = Input;

function ShowmenuDeploy(): JSX.Element {
  const dispatch = useDispatch();
  const [isShowmenuDeployModalVisible, setIsShowmenuDeployModalVisible] =
    React.useState<boolean>(false);
  const [showmenuDeployDescription, setShowmenuDeployDescription] =
    React.useState<string>("");
  const selectedLocation = useSelector(getSelectedLocation);
  const lastMovieQuery = useSelector(getLastMovieQuery);
  const lastShowDeploy = useSelector(getLastShowDeploy);

  const [
    queryKiosks,
    { data: kiosks, loading: kiosks_loading, error: kiosks_error },
  ] = useLazyQuery<kiosks, kiosksVariables>(
    gql`
      query kiosks($location_id: Int!) {
        kiosks(location_id: $location_id) {
          id
          name
          current_showmenu
          deploy_showmenu
          last_deploy
        }
      }
    `,
    {
      variables: { location_id: selectedLocation },
      fetchPolicy: "network-only",
    }
  );

  const [deployShowmenu, { loading: deployShowmenuLoading }] = useMutation<
    deploy_showmenu,
    deploy_showmenuVariables
  >(gql`
    mutation deploy_showmenu($input: Showmenu_deploy_input!) {
      deploy_showmenu(input: $input)
    }
  `);

  const { data: lastDeployData } = useSubscription<
    lastDeploy,
    lastDeployVariables
  >(
    gql`
      subscription lastDeploy($location_id: Int!) {
        lastDeploy(location_id: $location_id)
      }
    `,
    {
      variables: { location_id: selectedLocation },
      fetchPolicy: "no-cache",
    }
  );

  React.useEffect(() => {
    if (kiosks_error) {
      notification["error"]({
        message: "Hiba történt a kioskok betöltésekor",
      });
    }
  }, [kiosks_error]);

  React.useEffect(() => {
    if (lastDeployData) {
      dispatch(
        App.actions.setLastShowDeploy(
          DateTime.fromISO(lastDeployData.lastDeploy, { setZone: true })
        )
      );
    }
  }, [lastDeployData]);

  const showShowmenuDeployModal = () => {
    queryKiosks({ variables: { location_id: selectedLocation } });
    setShowmenuDeployDescription("");
    setIsShowmenuDeployModalVisible(true);
  };

  const handleShowmenuDeployModalOk = () => {
    deployShowmenu({
      variables: {
        input: {
          location_id: selectedLocation,
          comment: showmenuDeployDescription,
        },
      },
    })
      .then(() => {
        notification["success"]({
          message: "Sikeres aktiválás",
        });
        setIsShowmenuDeployModalVisible(false);
      })
      .catch(() => {
        notification["error"]({
          message: "Hiba történt az aktiválás során",
        });
      });
  };

  const handleShowmenuDeployModalCancel = () => {
    setIsShowmenuDeployModalVisible(false);
  };

  const handleShowmenuDeployDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setShowmenuDeployDescription(value);
  };

  const columns = [
    {
      title: "Név",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Kiosk id",
      dataIndex: "id",
      key: "id",
      render: (id: string) => (id.length > 8 ? id.substring(0, 7) : id),
    },
    {
      title: "Aktuális moziműsor",
      dataIndex: "current_showmenu",
      key: "current_showmenu",
    },
    {
      title: "Cél moziműsor",
      dataIndex: "deploy_showmenu",
      key: "deploy_showmenu",
    },
    {
      title: "Letöltött moziműsor",
      dataIndex: "downloaded_showmenu",
      key: "downloaded_showmenu",
    },
    {
      title: "Utolsó aktiválás",
      dataIndex: "last_deploy",
      key: "last_deploy",
      render: (last_deploy: string) =>
        DateTime.fromISO(last_deploy, { setZone: true })
          .setLocale("hu")
          .toFormat("yyyy LLLL dd. HH:mm"),
    },
  ];

  return (
    <Container>
      <LastQuery
        warn={
          lastMovieQuery !== undefined &&
          lastShowDeploy !== undefined &&
          lastMovieQuery < lastShowDeploy
        }
      >
        {lastMovieQuery &&
          "Megjelenített adatok lekérve: " +
            lastMovieQuery.setLocale("hu").toFormat("yyyy.MM.dd HH:mm")}
      </LastQuery>
      <LastUpdate>
        {lastShowDeploy &&
          "Utolsó aktiválás ideje: " +
            lastShowDeploy.setLocale("hu").toFormat("yyyy.MM.dd HH:mm")}
      </LastUpdate>
      <Modal
        title="Filmek, vetítések aktiválása a kioskokra"
        visible={isShowmenuDeployModalVisible}
        onOk={handleShowmenuDeployModalOk}
        onCancel={handleShowmenuDeployModalCancel}
        cancelText={"Mégse"}
        okText={"Aktiválás"}
        width={1200}
        confirmLoading={deployShowmenuLoading}
      >
        <Table
          dataSource={kiosks ? kiosks.kiosks : []}
          columns={columns}
          showHeader={true}
          style={{ width: "100%" }}
          pagination={false}
          rowKey={"id"}
          locale={{
            emptyText: <Empty description={"Nincs megjeleníthető adat"} />,
          }}
          loading={kiosks_loading}
        />
        <Form layout="vertical" style={{ paddingTop: "30px" }}>
          <Form.Item label="Megjegyzés">
            <TextArea
              rows={2}
              value={showmenuDeployDescription}
              onChange={handleShowmenuDeployDescriptionChange}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button type="primary" onClick={showShowmenuDeployModal}>
        Aktiválás
      </Button>
    </Container>
  );
}

export default ShowmenuDeploy;
