import styled, { keyframes } from "styled-components";

interface ILoginForm {
  error: boolean;
}

const shake = keyframes`
  0% { transform: translate(-49% , -49%) rotate(0deg); }
  10% { transform: translate(-51% , -52%) rotate(-1deg); }
  20% { transform: translate(-53%, -50%) rotate(1deg); }
  30% { transform: translate(-47%, -48%) rotate(0deg); }
  40% { transform: translate(-49%, -51%) rotate(1deg); }
  50% { transform: translate(-51%, -48%) rotate(-1deg); }
  60% { transform: translate(-53%, -49%) rotate(0deg); }
  70% { transform: translate(-47%, -49%) rotate(-1deg); }
  80% { transform: translate(-51%, -51%) rotate(1deg); }
  90% { transform: translate(-49%, -48%) rotate(0deg); }
  100% { transform: translate(-49%, -52%) rotate(-1deg); }
`;

export const LoginForm = styled.div<ILoginForm>`
  animation: ${(props) => (props.error ? shake : "")} 0.4s;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 500px;
  border: 2px solid rgba(28, 110, 164, 0.48);
  border-radius: 10px;
`;

export const Logo = styled.div`
  width: 100%;
  padding-bottom: 50px;
  & img {
    display: block;
    margin: auto;
  }
`;
