import { DateTime } from "luxon";
import { action } from "typesafe-actions";

export const actionTypes = {
  INCREASE_LOADING: "app/IncreaseLoading",
  DECREASE_LOADING: "app/DecreaseLoading",
  SET_CLIENT: "app/SetClient",
  SET_LOCATION: "app/SetLocation",
  SET_LAST_MOVIE_QUERY: "app/SetLastMovieQuery",
  SET_LAST_SHOW_DEPLOY: "app/SetLastShowDeploy",
};

export const actions = {
  increaseLoading: (): { type: string } => action(actionTypes.INCREASE_LOADING),
  decreaseLoading: (): { type: string } => action(actionTypes.DECREASE_LOADING),
  setClient: (id: number): { type: string } =>
    action(actionTypes.SET_CLIENT, id),
  setLocation: (id: number): { type: string } =>
    action(actionTypes.SET_LOCATION, id),
  setLastMovieQuery: (date: DateTime | undefined): { type: string } =>
    action(actionTypes.SET_LAST_MOVIE_QUERY, date),
  setLastShowDeploy: (date: DateTime | undefined): { type: string } =>
    action(actionTypes.SET_LAST_SHOW_DEPLOY, date),
};
