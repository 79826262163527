import * as React from "react";
import { Route, Switch, withRouter, Router } from "react-router";
import Login from "../containers/login/login";
import Main from "../containers/main/main";
import Cinema from "../containers/cinema/cinema";
import CinemaContent from "../containers/cinema_content/content";
import Empty from "../containers/empty/empty";
import PrivateRoute from "../components/utils/private-route";
import { useDispatch } from "react-redux";
import Auth from "../logic/redux/auth";
import { gql, useMutation } from "@apollo/client";
import { refresh_token } from "./__generated__/refresh_token";

export const RouteNames = {
  MAIN: "/",
  CINEMA: "/cinema",
  LOGIN: "/login",
};

export const CinemaRouteNames = {
  MOVIES: "/cinema/movies",
  HALLS: "/cinema/halls",
  TICKETS: "/cinema/tickets",
  DEPLOYS: "/cinema/deploys",
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Routes(props: any): JSX.Element {
  const dispatch = useDispatch();
  const [refreshTokenMutation, { data, loading }] =
    useMutation<refresh_token>(gql`
      mutation refresh_token {
        refresh_token
      }
    `);
  React.useEffect(() => {
    refreshTokenMutation().catch((e) => {
      console.log(e);
    });
  }, []);
  React.useEffect(() => {
    if (data) {
      sessionStorage.setItem("token", data.refresh_token);
      dispatch(Auth.actions.setUserLoggedIn());
      //return <Redirect to={RouteNames.MAIN} />;
    }
  }, [data]);

  return (
    <div className="App">
      <Router history={props.history}>
        {loading ? null : (
          <Switch location={props.location}>
            <Route exact={true} path={RouteNames.LOGIN} component={Login} />
            <PrivateRoute
              exact={false}
              path={RouteNames.MAIN}
              component={Main}
            />
          </Switch>
        )}
      </Router>
    </div>
  );
}

export const SubRoutes = (): JSX.Element => {
  return (
    <Switch>
      {/*<Route exact={true} path={RouteNames.MAIN} component={Main} />*/}
      <Route exact={false} path={RouteNames.CINEMA} component={Cinema} />
      <Route exact={true} path={RouteNames.MAIN} component={Cinema} />
    </Switch>
  );
};

export const CinemaRoutes = (): JSX.Element => {
  return (
    <Switch>
      <Route exact={true} path={CinemaRouteNames.HALLS} component={Empty} />
      <Route exact={true} path={CinemaRouteNames.TICKETS} component={Empty} />
      <Route exact={true} path={CinemaRouteNames.DEPLOYS} component={Empty} />
      <Route exact={false} path={RouteNames.MAIN} component={CinemaContent} />
    </Switch>
  );
};

export default withRouter(Routes);
