import * as React from "react";
import { SubRoutes } from "../../routes/routes";
import HeaderContent from "../../containers/main_header/header";
import { MainContainer } from "./style";

function Main(): JSX.Element {
  return (
    <MainContainer>
      <HeaderContent />
      <SubRoutes />
    </MainContainer>
  );
}

export default Main;
