import produce from "immer";
import { IAppReducer } from "./app-models";
import { actionTypes } from "./app-actions";
const initializeState: IAppReducer = {
  loading: 0,
  selectedClient: 0,
  selectedLocation: 0,
  lastMovieQuery: undefined,
  lastShowDeploy: undefined,
};

export default (
  state = initializeState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: { type: string; payload: any }
): IAppReducer =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.INCREASE_LOADING:
        draft.loading = state.loading + 1;
        return draft;
      case actionTypes.DECREASE_LOADING:
        draft.loading = state.loading - 1;
        return draft;
      case actionTypes.SET_CLIENT:
        draft.selectedClient = action.payload;
        return draft;
      case actionTypes.SET_LOCATION:
        draft.selectedLocation = action.payload;
        draft.lastMovieQuery = undefined;
        draft.lastShowDeploy = undefined;
        return draft;
      case actionTypes.SET_LAST_MOVIE_QUERY:
        draft.lastMovieQuery = action.payload;
        return draft;
      case actionTypes.SET_LAST_SHOW_DEPLOY:
        draft.lastShowDeploy = action.payload;
        return draft;
      default:
        return state;
    }
  });
