import * as React from "react";
import {
  Table,
  Form,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Select,
  Empty,
  Pagination,
  notification,
} from "antd";
import { gql, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { getSelectedLocation } from "../../logic/redux/app/app-selectors";

import locale from "antd/es/date-picker/locale/hu_HU";
import { halls, hallsVariables, halls_halls } from "./__generated__/halls";
import { showtimes, showtimesVariables } from "./__generated__/showtimes";

import { PaginatorContainer } from "./style";
import { DateTime } from "luxon";
import moment from "moment";

const { Option } = Select;

interface Data {
  show_id: number;
  expanded: boolean;
}

function MovieShowtimes(props: Data): JSX.Element {
  const per_page = 10;
  const selectedLocation = useSelector(getSelectedLocation);

  const [selectedShowtimeFilterDate, setSelectedShowtimeFilterDate] =
    React.useState<moment.Moment | null>(null);
  const [selectedShowtimeFilterTime, setSelectedShowtimeFilterTime] =
    React.useState<string | null>(null);
  const [selectedShowtimeFilterHall, setSelectedShowtimeFilterHall] =
    React.useState<number | null>(null);
  const [actualPage, setActualPage] = React.useState<number>(1);

  const {
    data: halls,
    /*loading: halls_loading,*/
    error: halls_error,
  } = useQuery<halls, hallsVariables>(
    gql`
      query halls($location_id: Int!) {
        halls(location_id: $location_id) {
          id
          name
        }
      }
    `,
    {
      variables: { location_id: selectedLocation },
    }
  );

  const {
    data: showtimesData,
    /*loading: showtimes_loading,*/
    error: showtimes_error,
  } = useQuery<showtimes, showtimesVariables>(
    gql`
      query showtimes($input: ShowtimeListRequest!) {
        showtimes(input: $input) {
          page_count
          showtimes {
            showtime
            hall {
              id
              name
            }
            occupancy {
              capacity
              available
            }
          }
        }
      }
    `,
    {
      variables: {
        input: {
          movie_id: props.show_id,
          page: actualPage,
          per_page: per_page,
          hall_id: selectedShowtimeFilterHall,
          date: selectedShowtimeFilterDate
            ? selectedShowtimeFilterDate.format("YYYY-MM-DD")
            : null,
          time: selectedShowtimeFilterTime,
        },
      },
    }
  );

  React.useEffect(() => {
    if (halls_error) {
      notification["error"]({
        message: "Hiba történt a termek betöltésekor",
      });
    }
  }, [halls_error]);

  React.useEffect(() => {
    if (showtimes_error) {
      notification["error"]({
        message: "Hiba történt a vetítések betöltésekor",
      });
    }
  }, [showtimes_error]);

  React.useEffect(() => {
    setSelectedShowtimeFilterDate(null);
    setSelectedShowtimeFilterTime(null);
    setSelectedShowtimeFilterHall(null);
  }, [props.expanded]);

  React.useEffect(() => {
    if (showtimesData && showtimesData.showtimes.page_count < actualPage) {
      setActualPage(1);
    }
  }, [actualPage, showtimesData]);

  function onChangeShowtimeFilterDate(
    date: moment.Moment | null
    /*dateString: string*/
  ) {
    if (date === null) {
      setSelectedShowtimeFilterDate(null);
    } else {
      setSelectedShowtimeFilterDate(date);
    }
  }

  function onChangeShowtimeFilterTime(
    date: moment.Moment | null,
    dateString: string
  ) {
    if (date === null) {
      setSelectedShowtimeFilterTime(null);
    } else {
      setSelectedShowtimeFilterTime(dateString);
    }
  }

  function onChangeHall(value: number) {
    if (value) {
      setSelectedShowtimeFilterHall(value);
    } else {
      setSelectedShowtimeFilterHall(null);
    }
  }

  function onPagerChange(value: number) {
    setActualPage(value);
  }

  const columns = [
    {
      title: "Dátum",
      dataIndex: "showtime",
      key: "showtime",
      render: (st: string) => DateTime.fromISO(st).toFormat("yyyy.MM.dd"),
    },
    {
      title: "Időpont",
      dataIndex: "showtime",
      key: "showtime",
      render: (st: string) => DateTime.fromISO(st).toFormat("HH:mm"),
    },
    {
      title: "Terem",
      dataIndex: "hall",
      key: "hall",
      render: (hall: halls_halls) => hall.name,
    },
    /*{
      title: "Telítettség",
      dataIndex: "occupancy",
      key: "occupancy",
      render: (occupancy: showtimes_showtimes_showtimes_occupancy) =>
        occupancy.capacity > 0
          ? `${
              Math.round(
                (10000 * (occupancy.capacity - occupancy.available)) /
                  occupancy.capacity
              ) / 100
            }% (${occupancy.capacity - occupancy.available}/${
              occupancy.capacity
            })`
          : "Nincs adat",
    },*/
  ];

  return (
    <>
      <Form layout={"vertical"}>
        <Row gutter={50}>
          <Col span={8}>
            <Form.Item label="Dátum">
              <DatePicker
                locale={locale}
                style={{ width: "100%" }}
                onChange={onChangeShowtimeFilterDate}
                value={selectedShowtimeFilterDate}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Időpont">
              <TimePicker
                format={"HH:mm"}
                style={{ width: "100%" }}
                locale={locale}
                onChange={onChangeShowtimeFilterTime}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Terem">
              <Select allowClear={true} onChange={onChangeHall}>
                {/*<Option value=""> </Option>*/}
                {halls &&
                  halls.halls.map((hall) => {
                    return (
                      <Option
                        key={"showtime_hall_filter" + hall.id.toString()}
                        value={hall.id.toString()}
                      >
                        {hall.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        dataSource={showtimesData ? [...showtimesData.showtimes.showtimes] : []}
        columns={columns}
        style={{ width: "100%" }}
        pagination={false}
        rowKey={(record) => record.showtime + record.hall.id}
        locale={{
          emptyText: <Empty description={"Nincs megjeleníthető adat"} />,
        }}
      />
      <PaginatorContainer>
        <Pagination
          defaultCurrent={1}
          current={actualPage}
          pageSize={per_page}
          total={
            showtimesData && showtimesData.showtimes.page_count
              ? showtimesData.showtimes.page_count * per_page
              : 1
          }
          onChange={onPagerChange}
          locale={locale}
          showSizeChanger={false}
        />
      </PaginatorContainer>
    </>
  );
}

export default MovieShowtimes;
