import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getSelectedClient } from "../../logic/redux/app/app-selectors";
import App from "../../logic/redux/app";
import { Select, notification } from "antd";
import { Container } from "./style";
import { gql, useQuery } from "@apollo/client";
import { clients } from "./__generated__/clients";
import { queryUpdater } from "../../helpers/utils";
import { useHistory, useLocation } from "react-router-dom";
const { Option } = Select;

export const CLIENT = "client";

function ClientSelector(): JSX.Element {
  const history = useHistory();
  const search = useLocation().search;
  const client = new URLSearchParams(search).get(CLIENT);
  const selectedClient = useSelector(getSelectedClient, shallowEqual);
  const dispatch = useDispatch();
  const {
    data: clients,
    //loading,
    error: clients_error,
  } = useQuery<clients>(
    gql`
      query clients {
        clients {
          id
          name
        }
      }
    `,
    { fetchPolicy: "no-cache" }
  );

  React.useEffect(() => {
    if (clients_error) {
      notification["error"]({
        message: "Hiba történt az adatok betöltésekor",
      });
    }
  }, [clients_error]);

  React.useEffect(() => {
    if (selectedClient) {
      const querystring = queryUpdater(
        search,
        CLIENT,
        selectedClient.toString()
      );
      history.push(`${history.location.pathname}${querystring}`);
    }
  }, [selectedClient]);

  React.useEffect(() => {
    if (client && clients) {
      const client_id = Number(client);
      const found = clients.clients.find((c) => c.id === client_id);
      if (found) {
        dispatch(App.actions.setClient(found.id));
      } else {
        if (clients.clients.length > 0) {
          dispatch(App.actions.setClient(clients.clients[0].id));
          const querystring = queryUpdater(
            search,
            CLIENT,
            clients.clients[0].id.toString()
          );
          history.push(`${history.location.pathname}${querystring}`);
        }
      }
    } else if (!client && clients) {
      if (clients.clients.length > 0) {
        dispatch(App.actions.setClient(clients.clients[0].id));
        const querystring = queryUpdater(
          search,
          CLIENT,
          clients.clients[0].id.toString()
        );
        history.push(`${history.location.pathname}${querystring}`);
      }
    }
  }, [client, clients]);

  function handleChange(value: number) {
    dispatch(App.actions.setClient(value));
  }

  return (
    <Container>
      {clients &&
        (clients.clients.length > 1 ? (
          <Select
            value={selectedClient}
            style={{ width: 220 }}
            onChange={handleChange}
          >
            {clients.clients.map((client) => {
              return (
                <Option key={"client_" + client.id} value={client.id}>
                  {client.name}
                </Option>
              );
            })}
          </Select>
        ) : (
          clients.clients.length === 1 && <div>{clients.clients[0].name}</div>
        ))}
    </Container>
  );
}

export default ClientSelector;
