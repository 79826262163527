import styled from "styled-components";

export const Title = styled.p`
  margin: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
`;

export const PaginatorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top: 20px;
`;
