import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import Auth from "../../logic/redux/auth";
import { getCurrentLocation } from "../../logic/redux/router/router-selectors";
import { RouteNames } from "../../routes/routes";

const PrivateRoute = (props: RouteProps): JSX.Element => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(Auth.select.selectLoggedIn);
  const location = useSelector(getCurrentLocation);
  console.log("PRIVATE ROUTE:", loggedIn);
  if (loggedIn) {
    return <Route {...props} />;
  } else {
    dispatch(Auth.actions.setLoginRedirectPath(location));
    const redirect = (): JSX.Element => (
      <Redirect to={{ pathname: RouteNames.LOGIN }} />
    );
    return <Route {...props} component={redirect} render={undefined} />;
  }
};

export default PrivateRoute;
